<template>
  <div>
    <div v-for="(item, index) in processedItems" :key="index" class="rosette-button-container">
      <BrandButton
        v-if="item.isVisible"
        :radius="50"
        shape="rounded"
        @click="item.action"
        class="dynamic-rosette-button"
      >
        <VueText :isSingleLine="true" color="white-100" sizeLevel="3">{{ item.label }}</VueText>
      </BrandButton>
    </div>
  </div>
</template>

<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'DynamicRosetteButton',
  mixins: [RosetteInfo, gtmUtils],
  components: {
    VueText,
    BrandButton,
  },
  props: {
    rosette: {
      type: Object,
    },
    hasActiveMarathons: {
      type: Boolean,
    },
    hasNpsSurvey: {
      type: Boolean,
    },
  },
  computed: {
    processedItems() {
      return [
        {
          id: 5,
          label: 'Katıl',
          type: 'NpsSurvey',
          action: this.openNpsSurveyPopup,
          isVisible: this.isVisibleJoinNow(this.rosette),
        },
        {
          id: 4,
          label: 'İzle',
          type: 'VirtualVisit',
          action: this.openVirtualVisit,
          isVisible: this.isVirtualVisit(this.rosette),
        },
        {
          id: 9,
          type: 'EOrder',
          label: 'Ön Sipariş',
          action: this.openEOrder,
          isVisible: this.isEOrder(this.rosette),
        },
        {
          id: 10,
          label: 'Oyna',
          type: 'WeeklyPlayable',
          action: this.goToPlayable,
          isVisible: this.isVisibleWeeklyPlayable(this.rosette),
        },
        {
          id: 6,
          label: 'Şef Destek Hattı',
          type: 'Chatbot',
          action: this.goToChatbot,
          isVisible: this.isVisibleChatbot(this.rosette),
        },
      ];
    },
    isVisibleJoinNow() {
      return rosette => this.checkVisibility(rosette, 5) && this.hasNpsSurvey;
    },
    isVirtualVisit() {
      return rosette => this.checkVisibility(rosette, 4);
    },
    isEOrder() {
      return rosette => this.checkVisibility(rosette, 9);
    },
    isVisibleWeeklyPlayable() {
      return rosette => this.checkVisibility(rosette, 10) && this.hasActiveMarathons;
    },
    isVisibleChatbot() {
      return rosette => {
        return this.checkVisibility(rosette, 6);
      };
    },
  },
  methods: {
    checkVisibility(rosette, typeId) {
      return rosette?.rosetTypeId === typeId && this.rosette.isSuccessible === 1;
    },
    goToPlayable() {
      const route = `${RoutesRoot.Secure.path}/${RoutesSecure.WeeklyPlayable.path}`;
      this.$router.push({ path: route });
    },
    openVirtualVisit() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'rozetlerim',
      });
      const route = `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`;
      this.$router.push({ path: route });
    },
    openNpsSurveyPopup() {
      this.$emit('openNpsSurveyPopup');
    },
    openEOrder() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'rozetlerim',
      });
      Common.getEcommerceUrl().then(res => {
        const { url } = res.data.Data;
        if (!url) return;
        window.location = url;
      });
    },
    goToChatbot() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Chatbot.path}`);
    },
  },
};
</script>

<style scoped lang="scss">
.rosette-button-container {
  padding: 0 15% !important;
}
.dynamic-rosette-button {
  height: 35px !important;
}
</style>
